<template>
  <div>
    <Modal
      :value="value"
      title="查看详情"
      @input="(val) => $emit('input', val)"
      footer-hide
      class="detail-modal"
    >
      <div class="detail-modal-content">
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">设备名称：</span>
          <span>{{ detail.name }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">所属基地：</span>
          <span>{{ detail.farmName }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">所属场景：</span>
          <span>{{ detail.sceneNames }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">设备key：</span>
          <span>{{ detail.deviceKey }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接地址：</span>
          <span>{{ detail.linkIp }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">连接端口：</span>
          <span>{{ detail.linkPort }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">是否为虚拟机：</span>
          <span>{{ detail.isFictitious =='1'?'是':'否' }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">更新时间：</span>
          <span>{{ detail.updateTime }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">创建时间：</span>
          <span>{{ detail.createTime }}</span>
        </div>
        <div class="detail-modal-item">
          <span class="detail-modal-item-label">状态：</span>
          <span>{{
            detail.status === 0
              ? "未启用"
              : detail.status === 1
              ? "启用"
              : "停用"
          }}</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.detail-modal {
  .detail-modal-content {
    .detail-modal-item {
      margin-bottom: 10px;
      display: flex;
      .detail-modal-item-label {
        display: inline-block;
        width: 110px;
        text-align: right;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }
}
</style>